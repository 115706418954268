<template>
  <span
    v-if="envName"
    :class="[`EnvLabel--${envName}`]"
    class="EnvLabel position-fixed d-flex flex-column p-2 m-2 emobg-color-white"
  >
    <span>
      Env: {{ envName }}
    </span>
    <span>
      Flavor: {{ flavor }}
    </span>
  </span>
</template>

<script>
import { ENV } from '@emobg/web-utils';
import config from '@/config';

export default {
  name: 'EnvLabel',
  inject: ['flavor'],
  created() {
    const isProduction = config.data.environment === ENV.production;
    this.envName = isProduction ? '' : config.data.environment;
  },
};
</script>

<style lang="scss" scoped>
.EnvLabel {
  bottom: 0;
  left: 0;
  z-index: 99999;
  opacity: 1;
  animation: blink 2s infinite;

  &--dev,
  &--development {
    background-color: #6ec190;
  }

  &--staging {
    background-color: #ffc845;
  }

  &--local {
    background-color: #278b96;
  }

  @keyframes blink {
    from {
      opacity: 0.2;
    }

    to {
      opacity: 1;
    }
  }
}
</style>
