<template>
  <Transition name="fade">
    <aside
      v-if="isModalOpened()"
      class="phModal_backdrop"
    >
      <Transition name="fade">
        <template v-if="!!current">
          <Component
            :is="current"
            v-bind="modalParams"
          />
        </template>
      </Transition>
    </aside>
  </Transition>
</template>

<script>
import Modal from '@/mixins/Modal';

export default {
  mixins: [Modal],

  data() {
    return {
      current: null,
    };
  },

  watch: {
    modalComponentShowed(newValue) {
      this.current = null;

      this.$nextTick(() => {
        this.current = newValue;
      });
    },
  },
};
</script>
