<template lang="html">
  <div class="phLoader w-100 h-100 emobg-background-color-white">
    <div class="d-flex align-items-center justify-content-center h-100">
      <div>
        <ui-loader
          :variant="LOADER.animatedCar"
          :label="`${$t('UiComponents.UiLoader.label')}`"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { LOADER } from '@emobg/web-components';

export default {
  name: 'Loader',

  created() {
    this.LOADER = LOADER;
  },
};
</script>
