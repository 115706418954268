<template>
  <Transition name="fade">
    <aside
      v-if="showBackdropWithAppsLink"
      id="UseMobileView"
      class="phModal_backdrop phModal_backdrop--over-view"
    >
      <div class="h-100">
        <Transition name="fade">
          <section class="p-4 w-100 h-100 ecod-gradient-background">
            <Logo
              class="mb-6"
              :variant="logoKeyName.CONTRAST_LOGO"
            />
            <h2 class="mb-3 emobg-title-xxl emobg-color-secondary-dark">
              {{ $t('use_mobile.title') }}
            </h2>

            <p class="mb-3 emobg-body-m-regular emobg-color-white">
              {{ $t('use_mobile.description') }}
            </p>

            <div class="d-flex">
              <a :href="links.ios">
                <img
                  :src="icons.DownloadAppStoreDefault"
                  class="mr-3"
                  alt="iOS"
                >
              </a>
              <a :href="links.android">
                <img
                  :src="icons.DownloadGooglePlayDefault"
                  alt="Android"
                >
              </a>
            </div>
          </section>
        </Transition>
      </div>
    </aside>
  </Transition>
</template>

<script>
import { COUNTRIES_ISO_CODES, getValue } from '@emobg/web-utils';
import { computed } from 'vue';

// config
import cfg from '@/config';

// Images
import DownloadAppStoreDefault from '@/assets/images/icons/download-app-store-default.svg';
import DownloadAppStoreHover from '@/assets/images/icons/download-app-store-hover.svg';
import DownloadGooglePlayDefault from '@/assets/images/icons/download-google-play-default.svg';
import DownloadGooglePlayHover from '@/assets/images/icons/download-google-play-hover.svg';
import Logo from '@/components/Logo/Logo';
// Directives
import Clicked from '@/directives/Clicked';
// Mixins
import CSOperatorMixin from '@/mixins/CSOperator';

// Constants
import { LOGOS_KEYNAMES } from '@/constants/assetsRepository';
import { useRoute } from 'vue-router/composables';
import { ANDROID, IOS } from './constants/appLinks.const';

const { name: operatorName } = cfg.data.csOperator;

export default {
  components: {
    Logo,
  },
  directives: {
    Clicked,
  },
  mixins: [
    CSOperatorMixin,
  ],
  setup() {
    const logoKeyName = LOGOS_KEYNAMES;
    const route = useRoute();
    const isResetPassword = computed(() => /reset-password/.test(route.fullPath));
    const isSignUp = computed(() => /signup|sign-up/.test(route.fullPath));
    const isTariffs = computed(() => /tariffs/.test(route.path));
    const isLegal = computed(() => /legal/.test(route.path));
    const requestByMobile = computed(() => getValue(route, 'query.mobile', false));
    const showBackdropWithAppsLink = computed(() => (!getValue(route, 'meta.responsive', false)
        && !isResetPassword?.value
        && !isTariffs?.value
        && !isLegal?.value
        && !isSignUp?.value));
    function getCountryAppLink(links) {
      return links[COUNTRIES_ISO_CODES.spain];
    }

    const icons = computed(() => ({
      DownloadAppStoreDefault,
      DownloadAppStoreHover,
      DownloadGooglePlayDefault,
      DownloadGooglePlayHover,
    }));
    const links = computed(() => ({
      android: getCountryAppLink(ANDROID),
      ios: getCountryAppLink(IOS),
    }));

    return {
      logoKeyName,
      isResetPassword,
      isSignUp,
      isTariffs,
      isLegal,
      requestByMobile,
      showBackdropWithAppsLink,
      icons,
      links,
      getCountryAppLink,
    };
  },

  data() {
    return {
      operatorName,
    };
  },
};

</script>
